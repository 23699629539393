import React, { useState } from "react";
// import { login as loginAPI } from './authService';
import { useNavigate } from "react-router-dom";

import { loginRequest } from "../../services/authApiCall";
import Swal from "sweetalert2";

const Login = () => {
  const loginInitialState = {
    email: "",
    password: "",
  };

  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const [login, setLogin] = useState(loginInitialState);
  const [error, setError] = useState(false);

  // onchange function for above state
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLogin({
      ...login,
      [name]: value,
    });
  };

  // onsubmit function for above state
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!login.email || !login.password) {
      setError(true);
      return;
    }
    const reqBody = {
      email: login.email,
      password: login.password,
    };

    try {
      const response = await loginRequest(reqBody);

      if (response.success === true) {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("user", JSON.stringify(response.data.user));
        // Swal.fire({
        //   icon: "success",
        //   title: "Login Success",
        //   text: "You are successfully logged in",
        // });
        navigate("/proposal");
      }
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: e.response.data.message,
      });
    }
  };

  return (
    <>
      <div class="container-fluid bg">
        <div class="row login-outer">
          <div class="col-md-6 col-sm-12 login-left">
            <div class="login-right-title">
              <div class="title1">
                {" "}
                <a href="https://acompworld.com/" target="blank">
                  <img
                    src="/static/logo.png"
                    alt="logo"
                    style={{ height: "50px" }}
                  />
                </a>
              </div>
              {/* <div class="title2 text-black">Proposal Builder</div> */}
            </div>
          </div>
          <div class="col-md-12 col-sm-12 login-box-container">
            <div class="card login-box">
              <div class="card-body">
                <h2 class="card-title text-center mb-4">Login</h2>
                <form>
                  <div class="mb-3">
                    <input
                      type="email"
                      placeholder="Enter Email"
                      name="email"
                      required
                      value={login.email}
                      onChange={(e) => {
                        e.target.value = e.target.value
                          .replace(/[^a-zA-Z0-9@.]/g, "")
                          .trim();
                        handleInputChange(e);
                      }}
                      class="form-control"
                      id="email"
                      aria-describedby="emailHelp"
                    />
                    <small className="error">
                      {error && !login.email ? "Email is required" : ""}
                      {error && login.email && !login.email.includes("@")
                        ? "Invalid email"
                        : ""}
                    </small>
                  </div>
                  <div class="mb-3 position-relative">
                    <input
                      type={showPassword ? "text" : "password"}
                      value={login.password}
                      name="password"
                      onChange={handleInputChange}
                      required
                      placeholder="Password"
                      class="form-control position-relative"
                      id="password"
                    />

                    <div className="password-visibility">
                      {showPassword ? (
                        <i
                          class="fas fa-eye-slash"
                          onClick={togglePasswordVisibility}
                        ></i>
                      ) : (
                        <i
                          class="fas fa-eye"
                          onClick={togglePasswordVisibility}
                        ></i>
                      )}
                    </div>
                    <small className="error">
                      {error && !login.password ? "Password is required" : ""}
                    </small>
                  </div>

                  <button
                    type="button"
                    onClick={handleSubmit}
                    class="btn btn-primary btn-login"
                  >
                    Login
                  </button>
                  <div class="text-center mt-3 ">
                    <hr class="forgot-line" />{" "}
                    {/* <a href="#" className="disable-pointer forgot-link">
                      Forgot Password?
                    </a> */}
                    <hr class="forgot-line" />
                  </div>
                  <div class="mt-3"></div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
