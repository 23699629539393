import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Form, Card } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import PreviewProposal from "./PreviewProposal";
import {
  getProposalSection,
  updateProposalSection,
  getProposalSectionDetails,
  getSelectedProposalSection,
  removeProposalSection,
  updateProposalSectionDetails,
  addProposalSection,
} from "../../services/proposalApiCall";
import Sidebar from "../../component/Sidebar";
import { getAllSectionSubSection } from "../../services/componentApiCall";
import Swal from "sweetalert2";

const ChooseSection = ({
  handleCancelChooseSection,
  handleHideAll,
  isEdit,
  ProposalID,
  selectedComponent,
}) => {
  const [showPreviewDownload, setShowPreviewDownload] = useState(false);
  const [sectionData, setSectionData] = useState([]);
  const [checkedSubsectionIds, setCheckedSubsectionIds] = useState([]);
  const [SectionName, setSectionName] = useState("");
  const [SectionContent, setSectionContent] = useState("");
  const [PSID, setPSID] = useState(0);

  // useEffect(() => {
  //   fetchProposalSection(ProposalID,selectedComponent);
  // }, [ProposalID,selectedComponent]);

  // const fetchProposalSection = async (ProposalID,selectedComponent) => {
  //   try {
  //     const response = await getProposalSection(ProposalID,selectedComponent);
  //     if (response && response.data) {
  //       setSectionData(response.data);

  //       //by default all the sections are checked
  //       const sectionIds = response.data.map((section) => section.SectionID);
  //       setCheckedSubsectionIds(sectionIds);

  //     }
  //   } catch (error) {
  //      console.log(error);
  //   }

  // }

  useEffect(() => {
    fetchSections();
  }, []);

  useEffect(() => {
    fetchSelectedProposalSection(ProposalID);
  }, [ProposalID]);

  const fetchSections = async () => {
    try {
      const response = await getAllSectionSubSection();
      if (response && response.data) {
        setSectionData(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSelectedProposalSection = async () => {
    try {
      const response = await getSelectedProposalSection(ProposalID);
      if (response && response.data) {
        const sectionIds = response.data.map((section) => section.SectionID);
        setCheckedSubsectionIds(sectionIds);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProposalSectionDetails = async (ProposalID, sectionid) => {
    try {
      const response = await getProposalSectionDetails(ProposalID, sectionid);
      if (response && response.data) {
        setSectionName(response.data[0]?.SectionName);
        setSectionContent(response.data[0]?.SectionContent);
        setPSID(response.data[0]?.PSID);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleShowPrievewDownload = () => {
    setShowPreviewDownload(true);
  };

  const handleCancelPrievewDownload = () => {
    setShowPreviewDownload(false);
  };

  const handleCheckboxChange = async (event, section) => {
    const { id, checked } = event.target;

    if (!checked) {
      // If the section is unchecked, remove it from the checkedSubsectionIds array
      setCheckedSubsectionIds((prevCheckedSubsectionIds) =>
        prevCheckedSubsectionIds.filter((subsectionId) => subsectionId != id)
      );
      try {
        await removeProposalSection(id, ProposalID);
        fetchSelectedProposalSection(ProposalID);
        return;
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const data = {
          sectionId: id,
        };
        const res = await addProposalSection(data, ProposalID);
        if (res && res.success === true) {
          fetchSelectedProposalSection(ProposalID);
        }
      } catch (error) {}
    }

    if (isEdit) {
      //the cheackbox isto be cheacked  if the sectionid is in the checkedSubsectionIds array
      const isSectionChecked = checkedSubsectionIds.includes(parseInt(id));

      if (isSectionChecked) {
        // If it's an update and section is checked, uncheck the checkbox
        setCheckedSubsectionIds((prevCheckedSubsectionIds) =>
          prevCheckedSubsectionIds.filter((subsectionId) => subsectionId != id)
        );
      } else {
        // If it's an update and section is unchecked, check the checkbox
        setCheckedSubsectionIds((prevCheckedSubsectionIds) => [
          ...prevCheckedSubsectionIds,
          parseInt(id),
        ]);
      }
    } else {
      if (checked) {
        setCheckedSubsectionIds((prevCheckedSubsectionIds) => [
          ...prevCheckedSubsectionIds,
          parseInt(id),
        ]);
      } else {
        setCheckedSubsectionIds((prevCheckedSubsectionIds) =>
          prevCheckedSubsectionIds.filter((subsectionId) => subsectionId != id)
        );
      }
    }
  };

  const handleSaveSectionData = async () => {
    try {
      const data = {
        SectionName: SectionName,
        SectionContent: SectionContent,
      };
      const response = await updateProposalSection(data, PSID);
      if (response && response.success === true) {
        console.log("Section data saved successfully");
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Section data saved successfully",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnClickSection = async (section) => {
    await fetchProposalSectionDetails(ProposalID, section.SectionID);
  };

  return (
    <>
      
        {!showPreviewDownload && (
          <Sidebar>
          <Container fluid className="mt-3">
            <Row>
              <Col xs={3}>
                <div className="sidebar card ms-1">
                  <div className="sidebar-content card-body">
                    <h3>Choose Section</h3>
                    <hr />
                    <Form>
                      {sectionData.map((section) => (
                        <div key={section.SectionID} className="mb-3 ms-3">
                          {/* Checkbox for main section */}
                          <Form.Check
                            type="checkbox"
                            id={section.SectionID}
                            onChange={(e) => handleCheckboxChange(e, section)}
                            checked={checkedSubsectionIds.includes(
                              section.SectionID
                            )}
                          />
                          <Form.Label
                           className={`${
                            SectionName == section.SectionName
                              ? "active"
                              : ""
                          } secton-label`}
                          style={{ cursor: "pointer" }}
                            onClick={() => {
                              handleOnClickSection(section);
                            }}
                          >
                            {section.SectionName}
                          </Form.Label>

                          {/* Subsections */}
                          {section.subsections &&
                            section.subsections.length > 0 && (
                              <ul className="list-unstyled">
                                {section.subsections.map((subsection) => (
                                  <li key={subsection.SectionID}>
                                    <Form.Check
                                      type="checkbox"
                                      id={subsection.SectionID}
                                      onChange={(e) =>
                                        handleCheckboxChange(e, subsection)
                                      }
                                      className="ms-4"
                                      checked={checkedSubsectionIds.includes(
                                        subsection.SectionID
                                      )}
                                    />
                                    <Form.Label
                                      style={{ cursor: "pointer" }}
                                      className={`${
                                        SectionName == subsection.SectionName
                                          ? "active" : ""  
                                      } secton-label`}
                                      onClick={() => {
                                        handleOnClickSection(subsection);
                                      }}
                                    >
                                      {subsection.SectionName}
                                    </Form.Label>
                                  </li>
                                ))}
                              </ul>
                            )}
                        </div>
                      ))}
                    </Form>
                  </div>
                </div>
              </Col>

              <Col xs={9}>
                <Card className="custom-card mt-1">
                  <Card.Body className="p-4">
                    <div className="container">
                      <Form className="mb-3 mt-4">
                        <Form.Group controlId="projectDetails" className="mb-3">
                          <Form.Label className="mb-2">
                            <h5 className="mb-4"> {SectionName}</h5>
                          </Form.Label>
                          <ReactQuill
                            theme="snow"
                            value={SectionContent}
                            onChange={setSectionContent}
                            style={{
                              height: "350px",
                              borderRadius: "5px",
                              marginBottom: "20px",
                            }}
                          />
                        </Form.Group>
                      </Form>
                      <div className="d-flex mt-5 justify-content-end">
                        <Button
                          variant="primary"
                          className="me-3 mt-2"
                          onClick={handleCancelChooseSection}
                        >
                          Back
                        </Button>
                        <Button
                          variant="primary"
                          className="me-3 mt-2"
                          onClick={handleSaveSectionData}
                        >
                          Update Section
                        </Button>
                        <Button
                          variant="primary"
                          className="me-3 mt-2"
                          onClick={handleShowPrievewDownload}
                        >
                          Preview
                        </Button>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
          </Sidebar>
        )}

    
      {showPreviewDownload && (
          <PreviewProposal
            cancel={handleCancelPrievewDownload}
            ProposalID={ProposalID}
          />
        )}
     
    </>
  );
};

export default ChooseSection;
