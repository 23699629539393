import React, { useEffect, useState } from "react";
import { Row, Col, InputGroup, FormControl, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import AddSubSection from "./AddSubSection";
import Swal from "sweetalert2";
import { getAllSubSections,deleteSection } from "../../services/sectionApiCall";

const SubSection = ({ close, sectionId, parentSectionName }) => {
  const [search, setSearch] = useState("");
  const [showAddSubSection, setShowAddSubSection] = useState(false);
  const [subSectionData, setSubSectionData] = useState([]);
  const [filteredSubSections, setFilteredSubSections] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const[selectedSubSection,setSelectedSubSection] = useState(null);
 

  useEffect(() => {
    fetchSubSections(sectionId);
  }, [sectionId]);

  const fetchSubSections = async (sectionId) => {
    try {
      const response = await getAllSubSections(sectionId);
      if (response && response.data) {
       
        setSubSectionData( response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleAddSubSection = (e) => {
    setShowAddSubSection(true);
  };

  const handleCloseModal = () => {
    setShowAddSubSection(false);
    fetchSubSections(sectionId);
  };

  const handleUpdateSubSection = (id,subsection) => {
    setSelectedSubSection(subsection);
    setShowAddSubSection(true);
    setIsUpdate(true);
    
  };

  const handleDeleteSubsection = (sectionId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this Sub Section?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
         const response = deleteSection(sectionId);
         if (response) {
          Swal.fire("Deleted!", "Sub Section has been deleted.", "success");
          fetchSubSections(sectionId);
        }
      }
    });
  };

  // FILTER SUB SECTIONS  BY SECTION NAME 

  useEffect(() => {
    if (search) {
      const filteredData = subSectionData.filter(
        (subSection) =>
          subSection.SectionName.toLowerCase().includes(search.toLowerCase())
      );
      setFilteredSubSections(filteredData);
    } else {
      setFilteredSubSections(subSectionData);
    }
  }, [search, subSectionData]);


  return (
    <>
      <div className="page-heading">Sub Sections</div>
      <div className="p-2">
        <Row className="mb-3">
          <Col xs={4}>
            <InputGroup>
              <FormControl
                placeholder="Search"
                aria-label="Search"
                aria-describedby="basic-addon2"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <Button variant="primary" id="button-addon2 ">
                <i className="fas fa-search"></i>
              </Button>
            </InputGroup>
          </Col>
          <Col xs={8} className="text-end">
            <Button
              variant="primary"
              className="me-2"
              onClick={handleAddSubSection}
            >
              <i className="fas fa-plus me-2"></i> New Sub Section
            </Button>
            <Button variant="secondary" onClick={close}>
              Back
            </Button>
          </Col>
        </Row>
        <hr />

        <div className="section-title ms-2">{parentSectionName}</div>
          {
            filteredSubSections && filteredSubSections.length > 0 ? (
              filteredSubSections.map((subSection, index) => (
                <Row className="mt-3 customer-css mx-1 " key={index}>
                  <Col xs={9}>
                    <div className="customer-name">{subSection.SectionName}</div>
                  </Col>

                  <Col xs={3} className="ms-0">
                    <div className="action-icons ">
                      <Link
                        className="action-icon mr-2"
                        onClick={() => handleUpdateSubSection(subSection.SectionID,subSection)}
                      >
                        <i className="fas fa-edit"></i>
                      </Link>
                      <Link
                        className="action-icon"
                        onClick={() => handleDeleteSubsection(subSection.SectionID)}
                      >
                        <i className="fas fa-trash-alt"></i>
                      </Link>
                    </div>
                  </Col>
                </Row>
              ))
            ) : (
              <div className="text-center mt-5">No Sub Sections Found</div>
            )
          }
      </div>
      {showAddSubSection && (
        <>
          <AddSubSection
            showAddSubSection={showAddSubSection}
            handleCloseModal={handleCloseModal}
            parentSectionId={sectionId}
            isUpdate={isUpdate}
            selectedSubSection={selectedSubSection}
            
          />
        </>
      )}
    </>
  );
};

export default SubSection;
