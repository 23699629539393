import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  Col,
  Row,
  Button,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import Topbar from "../../component/Topbar";
import AddNewSection from "./AddNewSection";
import Sidebar from "../../component/Sidebar";
import { getAllSections, deleteSection } from "../../services/sectionApiCall";
import SubSection from "./SubSection";
import Swal from "sweetalert2";

const SectionSetting = () => {
  const [showAddNewSection, setShowAddNewSection] = useState(false);
  const [sectionData, setSectionData] = useState([]);
  const [filteredSections, setFilteredSections] = useState([]);
  const [search, setSearch] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);
  const [sectionId, setSectionId] = useState(null);
  const [showSubSectionPage, setShowSubSectionPage] = useState(false);
  const [sectionName, setSectionName] = useState("");

  useEffect(() => {
    fetchSections();
  }, []);

  const fetchSections = async () => {
    try {
      const response = await getAllSections();
      if (response && response.data) {
        setSectionData(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (search) {
      const filteredData = sectionData.filter((section) =>
        section.SectionName.toLowerCase().includes(search.toLowerCase())
      );
      setFilteredSections(filteredData);
    } else {
      setFilteredSections(sectionData);
    }
  }, [search, sectionData]);

  const handleShowSubSection = (sectionId,sectionName) => {
    setShowSubSectionPage(true);
    setSectionId(sectionId);
    setSectionName(sectionName);
  };

  const handleCloseSubSection = () => {
    setShowSubSectionPage(false);
    setSectionId(null);
    fetchSections();
  };

  const handleUpdateSection = (sectionId) => {
    setIsUpdate(true);
    setSectionId(sectionId);
    setShowAddNewSection(true);
  };

  const handleDeleteSection = async (sectionId,totalSubSections) => {

    try {
      if(totalSubSections > 0){
        Swal.fire({
          title: "Sub Sections are available",
          text: "Please delete all sub sections first",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        });
        return;
      }else{

      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You want to delete this section",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      });
      if (result.isConfirmed) {
        const response = await deleteSection(sectionId);
        if (response.success === true) {
          Swal.fire("Deleted!", "Section has been deleted.", "success");
          fetchSections();
        }
      }
    }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseSection = () => {
    setShowAddNewSection(false);
    setIsUpdate(false);
    setSectionId(null);
    fetchSections();
  };


  return (
    <>
      <>
        <Topbar />
        <Sidebar>
          {!showAddNewSection && !showSubSectionPage && (
            <>
              <div className="page-heading">Sections</div>

              <div className="p-4">
                <Row className="mb-3">
                  <Col xs={4}>
                    <InputGroup>
                      <FormControl
                        placeholder="Search"
                        aria-label="Search"
                        aria-describedby="basic-addon2"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                      <Button variant="primary" id="button-addon2 ">
                        <i className="fas fa-search"></i>
                      </Button>
                    </InputGroup>
                  </Col>
                  <Col xs={8} className="text-end">
                    <Button
                      variant="primary"
                      onClick={() => setShowAddNewSection(true)}
                    >
                      <i className="fas fa-plus me-2"></i> New Section
                    </Button>
                  </Col>
                </Row>
                <hr />

                {filteredSections.length > 0 ? (
                  filteredSections.map((section) => (
                    <>
                      <Row className="mt-3 customer-css mx-1 ">
                        <Col xs={7}>
                          <div className="customer-name">
                            {section.SectionName}
                          </div>
                        </Col>
                        <Col xs={3}>
                          <Button
                            variant="primary"
                            className="btn-sm"
                            onClick={() =>
                              handleShowSubSection(section.SectionID, section.SectionName)
                            }
                          >
                            {section.totalSubSections} Sub Section
                          </Button>
                        </Col>
                        <Col xs={2} className="ms-0">
                          <div className="action-icons ">
                            <Link
                              className="action-icon mr-2"
                              onClick={() =>
                                handleUpdateSection(section.SectionID)
                              }
                            >
                              <i className="fas fa-edit"></i>
                            </Link>
                            <Link
                              className="action-icon"
                              onClick={() =>
                                handleDeleteSection(section.SectionID,section.totalSubSections)
                              }
                            >
                              <i className="fas fa-trash-alt"></i>
                            </Link>
                          </div>
                        </Col>
                      </Row>
                    </>
                  ))
                ) : (
                  <div>No data found</div>
                )}
              </div>
            </>
          )}

          {showAddNewSection && (
            <>
              <AddNewSection
                close={handleCloseSection}
                isUpdate={isUpdate}
                sectionId={sectionId}
              />
            </>
          )}

          {showSubSectionPage && (
            <>
              <SubSection close={handleCloseSubSection}
               sectionId={sectionId}
                parentSectionName={sectionName}
               />
            </>
          )}
        </Sidebar>
      </>
    </>
  );
};

export default SectionSetting;
