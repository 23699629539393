import React, { useState, useEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import ReactQuill from "react-quill";
import { addSubSection, updateSubSection } from "../../services/sectionApiCall";
import Swal from "sweetalert2";

const AddSubSection = ({
  showAddSubSection,
  handleCloseModal,
  parentSectionId,
  isUpdate,
  selectedSubSection,
}) => {
  const [subSectionId, setSubSectionId] = useState(null);
  useEffect(() => {
    if (isUpdate) {
      setSectionName(selectedSubSection.SectionName);
      setSectionContent(selectedSubSection.SectionContent);
      setSubSectionId(selectedSubSection.SectionID);
    }
  }, [isUpdate, selectedSubSection]);
  const [SectionContent, setSectionContent] = useState("");
  const [sectionName, setSectionName] = useState("");
  const [errorMessages, setErrorMessages] = useState({});

  const handleAddUpdateSection = async () => {
    try {
      let errors = {};
      if (!sectionName) {
        errors.sectionName = "Section Name is required";
      }
      if (!SectionContent) {
        errors.sectionDescription = "Section Description is required";
      }
      if (Object.keys(errors).length > 0) {
        setErrorMessages(errors);
        return;
      }

      if (Object.keys(errors).length === 0) {
        const sectionData = {
          SectionName: sectionName,
          SectionContent: SectionContent,
        };

        if (isUpdate) {
          try {
            const response = await updateSubSection(
              subSectionId,
              sectionData
            );
            if (response.success === true) {
              Swal.fire({
                icon: "success",
                title: "Sub Section Updated Successfully",
              });

              handleCloseModal();
            }
          } catch (error) {
            console.log(error);
          }
        } else {
          try {
            const response = await addSubSection(parentSectionId, sectionData);
            if (response.success === true) {
              Swal.fire({
                icon: "success",
                title: "Sub Section Added Successfully",
              });
              handleCloseModal();
            }
          } catch (error) {
            console.log(error);
          }
        }
      }
      setErrorMessages({});
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal show={showAddSubSection} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            {isUpdate ? "Update Sub Section" : "Add Sub Section"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="mt-3">
            <Form.Group className="mb-3">
              <Form.Label>Section Title</Form.Label>
              <Form.Control
                type="text"
                id="sectionTitle"
                onChange={(e) => setSectionName(e.target.value)}
                value={sectionName}
                onFocus={(e) => setErrorMessages({})}
              />
              {errorMessages.sectionName && (
                <small className="error">{errorMessages.sectionName}</small>
              )}
            </Form.Group>

            <Form.Group className="mb-5">
              <Form.Label>Section Description</Form.Label>
              <ReactQuill
                theme="snow"
                value={SectionContent}
                onChange={setSectionContent}
                style={{ height: "200px" }}
                onFocus={(e) => setErrorMessages({})}
              />
            </Form.Group>

            {errorMessages?.sectionDescription && (
              <small className="error ">
                {errorMessages.sectionDescription}
              </small>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={() => handleAddUpdateSection()}>
            {isUpdate ? "Update" : "Save"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddSubSection;
