import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";

const Sidebar = ({ children }) => {
  const location = useLocation();
  const currentUrl = location.pathname;
  const [pageUrl, setPageUrl] = useState("");
  const [showReportsDropdown, setShowReportsDropdown] = useState(false);

  const toggleReportsDropdown = () => {
    setShowReportsDropdown(!showReportsDropdown);
  };
  useEffect(() => {
    setPageUrl(currentUrl.split("/")[1]);
    console.log("currentUrl", currentUrl);
    setShowReportsDropdown(
      currentUrl.includes("/section") ||
      currentUrl.includes("/component")
    );
  }, [currentUrl]);
  

  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  return (
    <>
      <div className="wrapper d-flex align-items-stretch ">
        <nav id="sidebar" className={classNames({ active: isSidebarVisible })}>
          <div className="custom-menu">
            <button
              type="button"
              id="sidebarCollapse"
              className="btn btn-primary"
              onClick={toggleSidebar}
            >
              <i className="fa fa-bars"></i>
              <span className="sr-only">Toggle Menu</span>
            </button>
          </div>

          <ul className="list-unstyled components mb-5">
            <li className={classNames({ active: currentUrl.startsWith("/proposal") })}>
              <Link to="/proposal">
                <span className="fa fa-sticky-note mr-3"></span> Proposals
              </Link>
            </li>

            <li className={classNames({ active: currentUrl === "/customer" })}>
              <Link to="/customer">
                <span className="fa fa-user mr-3"></span> Customers
              </Link>
            </li>

            <li>
              <a
                href="#"
                onClick={toggleReportsDropdown}
                className={classNames({ active: showReportsDropdown })}
              >
            
                <span className="fa fa-cog mr-3"></span>
                 Settings
              </a>
              {showReportsDropdown && (
                <ul className="list-unstyled components">
                  <li  className={classNames({ active: currentUrl === "/section" })}>
                    <Link
                      className={classNames("sidebar__item", {
                        active: currentUrl === "/section"
                      })}
                      to="/section"
                    >
                      Sections
                    </Link>
                  </li>
                  <li  className={classNames({ active: currentUrl === "/component" })}>
                    <Link
                      className={classNames("sidebar__item", {
                        active: currentUrl === "/component"
                      })}
                      to="/component"
                    >
                      Components
                    </Link>
                  </li>
                </ul>
              )}
            </li>
          </ul>
        </nav>

        {/* <!-- Page Content  --> */}
        <div id="content" className="p-4 p-md-5 pt-5">
          {children}
        </div>
      </div>
    </>
  );
};

export default Sidebar;
