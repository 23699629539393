import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Proposal from './pages/proposal/Proposal';
import Customer from './pages/customer/Customer';
import ComponentSetting from './pages/component/ComponentSetting';
import SectionSetting from './pages/section/SectionSetting';
import Login from './pages/auth/Login';
import { RoutesProtected } from './component/ProtectedRotes';



function App() {
  const isUserLoggedIn = !!localStorage.getItem("token");

  return (
    <Router> {/* Wrap your components with Router */}
      <div>
       
        <Routes>
        <Route
            path="/"
            element={
              isUserLoggedIn ? (
                <Navigate to="/proposal" replace />
              ) : (
                <Login />
              )
            }
          />
          <Route path="/"
           element={<Login />} />
          <Route path="/proposal"
           element={<RoutesProtected cmp={Proposal} />} />
          <Route path="/customer" element={<RoutesProtected cmp={Customer} />} />
          <Route path="/component" element={<RoutesProtected cmp={ComponentSetting }/>} />
          <Route path="/section" element={<RoutesProtected cmp={SectionSetting} />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
