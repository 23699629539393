import { apiRequest } from "./apiRequest";

export const getAllCustomers = async (searchKey) => {
  const api = await apiRequest({
    url: `/customer/`,
    method: "get",
    header: true,
  });
  return api.data;
};

export const addCustomer = async (post) => {
  const api = await apiRequest({
    url: "/customer/",
    method: "post",
    body: post,
    header: true,
  });
  return api.data;
};

export const updateCustomer = async (data,Id) => {
  const api = await apiRequest({
    url: `/customer/${Id}`,
    method: "put",
    body: data,
    header: true,
  });
  return api.data;
};

export const deleteCustomer = async (id) => {
  const api = await apiRequest({
    url: `/customer/${id}`,
    method: "delete",
    header: true,
  });
  return api.data;
};

export const getCustomer = async (id) => {
  const api = await apiRequest({
    url: `/customer/${id}`,
    method: "get",
    header: true,
  });
  return api.data;
};

