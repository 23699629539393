import { apiRequest } from "./apiRequest";

export const getAllSections = async () => {
    const api = await apiRequest({
        url: `/section/`,
        method: "get",
        header: true,
    });
    return api.data;
    };

    export const deleteSection = async (id) => {
    const api = await apiRequest({
        url: `/section/${id}`,
        method: "delete",
        header: true,
    });
    return api.data;
    };

    export const addSection = async (data) => {
        console.log("data",data)
    const api = await apiRequest({
        url: `/section/`,
        method: "post",
        body: data,
        header: true,
     
    });

    return api.data;
    };

    export const updateSection = async (Id,data) => {
    const api = await apiRequest({
        url: `/section/${Id}`,
        method: "put",
        header: true,
        body: data,
    });

    return api.data;
    }
    export const getAllSubSections = async (sectionId) => {
    const api = await apiRequest({
        url: `/section/${sectionId}/sub-section`,
        method: "get",
        header: true,
    });
    return api.data;
    };

    export const deleteSubSection = async (sectionId,subSectionId) => {
    const api = await apiRequest({
        url: `/section/${sectionId}/sub-section/${subSectionId}`,
        method: "delete",
        header: true,
    });
    return api.data;
    }

    export const getSectionById = async (sectionId) => {
    const api = await apiRequest({
        url: `/section/${sectionId}`,
        method: "get",
        header: true,
    });
    return api.data;
    }

    export const addSubSection = async (sectionId,data) => {
    const api = await apiRequest({
        url: `/section/${sectionId}/sub-section`,
        method: "post",
        body: data,
        header: true,
    });
    return api.data;
    }

    export const updateSubSection = async (subSectionId,data) => {
    const api = await apiRequest({
        url: `/section/${subSectionId}/sub-section`,
        method: "put",
        body: data,
        header: true,
    });
    return api.data;
    }
    



