import React, { useState, useEffect } from "react";
import Sidebar from "../../component/Sidebar";
import Topbar from "../../component/Topbar"; // Capitalized "Topbar"
import { Row, Col, InputGroup, FormControl, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import NewProposalStep1 from "./NewProposalStep1";
import { getAllProposal, deleteProposal,getPreviewProposal } from "../../services/proposalApiCall";
import Table from "../../component/Table";
import Swal from "sweetalert2";
import htmlDocx from "html-docx-js/dist/html-docx";
import PreviewProposal from "./PreviewProposal";

const Proposal = () => {
  const [showProposalStep1, setShowProposalStep1] = useState(false);
  const [hideAll, setHideAll] = useState(false);
  const [proposalData, setProposalData] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredProposal, setFilteredProposal] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedProposal, setSelectedProposal] = useState({});
  const [showPreviewProposal ,setShowPreviewProposal] = useState(false);
  const [proposalId,setProposalId] = useState(null);


  useEffect(() => {
    fetchProposal();
  }, []);

 


  const fetchProposal = async () => {
    try {
      const response = await getAllProposal();
      if (response && response.data) {
        setProposalData(response.data);
        console.log(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  

  const sizePerPageList = [
    {
      text: "10",
      value: 10,
    },
    {
      text: "20",
      value: 20,
    },
  ];

  // Define columns for the table
  const columns = [
    {
      Header: "Title",
      accessor: "Title",
      sort: true,
      Cell: (row) => (
        // Apply onClick event handler to the title
        <span
          style={{ cursor: "pointer" }}
          onClick={() => handleTitleClick(row.row.original.ProposalID)}
        >
          {row.value}
        </span>
      ),
    },
    {
      Header: "Customer ",
      accessor: "CustomerName",
      sort: true,
    },
    {
      Header: "Status",
      accessor: "IsActive",
      sort: true,
      Cell: (row) => (row.value.data[0] === 1 ? "Active" : "Inactive"),

      // Assuming "IsActive" is a boolean field
    },
    {
      Header: "#Components",
      accessor: "ComponentCount",
      sort: true,
    },
    {
      Header: "Action",
      accessor: "id",
      sort: false,
      classes: "table-action",
      Cell: (row) => (
        <>
          <ActionColumn {...row} />
        </>
      ),
    },
  ];

  const ActionColumn = ({ row }) => {
    let rowOriginal = row.original;
    return (
      <>
        <Link
          className="action-icon"
          onClick={() => handleEditProposal(rowOriginal)}
        >
          <i className="fas fa-edit"></i>
        </Link>{" "}
        <Link
          className="action-icon"
          onClick={() => handleDeleteProposal(rowOriginal.ProposalID)}
        >
          <i className="fas fa-trash-alt"></i>
        </Link>{" "}
        <Link className="action-icon"
        onClick={() => handleDownload(rowOriginal.ProposalID)}
        >
          <i className="fas fa-download"></i>
        </Link>
      </>
    );
  };

  const handleCancelProposalStep1 = () => {
    setShowProposalStep1(false);
    setIsEdit(false);
    setSelectedProposal({});
    fetchProposal();
  };

  const handleHideAll = () => {
    setHideAll(false);
  };

  const handleShowProposalStep1 = () => {
    setShowProposalStep1(true);
  };

  // filter proposal data based on search  title and details
  useEffect(() => {
    setFilteredProposal(
      proposalData.filter(
        (proposal) =>
          proposal.Title.toLowerCase().includes(search.toLowerCase()) ||
          proposal.Details.toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [search, proposalData]);

  const handleDeleteProposal = async (id) => {
    try {
      // conformation alert
      Swal.fire({
        title: "Are you sure?",
        text: "You want to delete this proposal?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await deleteProposal(id);
          if (response && response.success === true) {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Proposal deleted successfully",
            });
            fetchProposal();
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: "Proposal not deleted",
            });
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditProposal = (id) => {
    setSelectedProposal(id);
    setIsEdit(true);
    setShowProposalStep1(true);
  };

  const handleDownload = async (ProposalID) => {

    const response = await getPreviewProposal(ProposalID);
    // Combine section names and contents into a single string
    const combinedContent = response.data
      .map(
        (item) =>
          `<h5>${item.SectionName}</h5><p>${item.SectionContent}</p>`
      )
      .join("");

    try {
      // Convert HTML to docx format
      const docxContent = htmlDocx.asBlob(combinedContent);

      // Create an anchor element to initiate download
      const element = document.createElement("a");

      // Set properties for the anchor element
      element.href = URL.createObjectURL(docxContent);
      element.download = "proposal.docx";

      // Append the anchor element to the document body (required for Firefox)
      document.body.appendChild(element);

      // Initiate the download
      element.click();

      // Clean up by revoking the object URL
      URL.revokeObjectURL(element.href);

      // Remove the anchor element from the document body
      document.body.removeChild(element);
    } catch (error) {
      console.error("Error converting HTML to docx:", error);
    }
  };


  const handleTitleClick = (proposalId) => {
    setProposalId(proposalId);
    setShowPreviewProposal(true);
  };

  const handleCancelPreviewProposal = () => {
    setShowPreviewProposal(false);
  }
  return (
    <>
      <Topbar />
      {!hideAll && (
        <>
          {!showProposalStep1 && 
          !showPreviewProposal &&
          (
            <Sidebar>
              <div className="page-heading">Proposals</div>
              <div className="p-2">
                <Row className="mb-3">
                  <Col xs={4}>
                    <InputGroup>
                      <FormControl
                        placeholder="Search"
                        aria-label="Search"
                        aria-describedby="basic-addon2"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                      <Button variant="primary" id="button-addon2">
                        <i className="fas fa-search"></i>
                      </Button>
                    </InputGroup>
                  </Col>
                  <Col xs={8} className="text-end">
                    <Button
                      variant="primary"
                      onClick={() => setShowProposalStep1(true)}
                    >
                      <i className="fas fa-plus me-2"></i> Add Proposal
                    </Button>
                  </Col>
                </Row>
                <hr />

                <Table
                  columns={columns}
                  data={filteredProposal}
                  pageSize={10}
                  sizePerPageList={sizePerPageList}
                  isSortable={true}
                  pagination={true}
                  isSearchable={false}
                  theadClass="table-light"
                  searchBoxClass="mb-5"
                  paginationClass="gridPaging"
                />
              </div>
            </Sidebar>
          )}
        </>
      )}

      {showProposalStep1 && (
        <NewProposalStep1
          handleCancelProposalstep1={handleCancelProposalStep1}
          handleHideAll={handleHideAll}
          handleshowProposalStep1={handleShowProposalStep1}
          isEdit={isEdit}
          Proposal={selectedProposal}
        />
      )}

      {showPreviewProposal && (
        <PreviewProposal
          cancel={handleCancelPreviewProposal}
          ProposalID={proposalId}
        />
      )}
    </>
  );
};

export default Proposal;
