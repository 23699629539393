import { apiRequest } from "./apiRequest";

export const getAllProposal = async () => {
  const api = await apiRequest({
    url: `/proposal/`,
    method: "get",
    header: true,
  });
  return api.data;
};

export const addProposal = async (post) => {
  const api = await apiRequest({
    url: "/proposal/",
    method: "post",
    body: post,
    header: true,
  });
  return api.data;
};

export const deleteProposal = async (id) => {
    const api = await apiRequest({
        url: `/proposal/${id}`,
        method: "delete",
        header: true,
    });
    return api.data;
    };

export const updateProposal = async (data,Id) => {
    const api = await apiRequest({
        url: `/proposal/${Id}`,
        method: "put",
        body: data,
        header: true,
    });
    return api.data;
};


export const getProposalComponent = async ( id ) => {
  const api = await apiRequest({
    url: `/proposal/${id}/components`,
    method: "get",
    header: true,
  });
  return api.data;
};

export const getProposalSection = async ( id,selectedComponent ) => {
  const api = await apiRequest({
    url:`/proposal/${id}/sections`,
    method:"post",
    body: selectedComponent,
    header: true
  })
  return api.data
}

export const updateProposalSection = async (data,Id) => {
  const api = await apiRequest({
      url: `/proposal/${Id}/sections`,
      method: "put",
      body: data,
      header: true,
  });
  return api.data;
}

export const getPreviewProposal = async ( proposalId ) => {
  const api = await apiRequest({
    url:`/proposal/${proposalId}/preview`,
    method:"get",
    header: true
  })
  return api.data
}



export const getProposalSectionDetails = async ( ProposalID , sectionid ) => {
  const api = await apiRequest({
    url:`/proposal/${ProposalID}/sections/${sectionid}`,
    method:"get",
    header: true
  })
  return api.data
}

export const getSelectedProposalSection = async ( ProposalID ) => {
  const api = await apiRequest({
    url:`/proposal/${ProposalID}/sections`,
    method:"get",
    header: true
  })
  return api.data
}
 

export const removeProposalSection = async (sectionId,proposalId ) => {
  const api = await apiRequest({
    url:`/proposal/${proposalId}/sections/${sectionId}`,
    method:"delete",
    header: true
  })
  return api.data
}

export const updateProposalSectionDetails = async (id,data) => {
  const api = await apiRequest({
    url:`/proposal/${id}/proposalsection`,
    method:"put",
    body: data,
    header: true
  })
  return api.data
}

export const addProposalSection = async (sectionId,id) => {
  const api = await apiRequest({
    url:`/proposal/${id}/addsections`,
    method:"post",
    body: sectionId,
    header: true
  })
  return api.data
}