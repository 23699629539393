import { apiRequest } from "./apiRequest";

export const getAllComponents = async () => {
  const api = await apiRequest({
    url: "/component/",
    method: "get",
    header: true,
  });
  return api.data;
};

export const addComponent = async (post) => {
  const api = await apiRequest({
    url: "/component/",
    method: "post",
    body: post,
    header: true,
  });
  return api.data;
};

export const updateComponent = async (data, Id) => {
  const api = await apiRequest({
    url: `/component/${Id}`,
    method: "put",
    body: data,
    header: true,
  });
  return api.data;
};

export const deleteComponent = async (id) => {
  const api = await apiRequest({
    url: `/component/${id}`,
    method: "delete",
    header: true,
  });
  return api.data;
};

export const getComponent = async (id) => {
  const api = await apiRequest({
    url: `/component/${id}`,
    method: "get",
    header: true,
  });
  return api.data;
};

export const getAllSectionSubSection = async () => {
  const api = await apiRequest({
    url: "/component/sections",
    method: "get",
    header: true,
  });
  return api.data;
};

export const mapSectionComponent = async (data, Id) => {
  const api = await apiRequest({
    url: `/component/${Id}/sections`,
    method: "post",
    body: data,
    header: true,
  });
  return api.data;
};

export const updateSectionComponent = async (data, Id) => {
  const api = await apiRequest({
    url: `/component/${Id}/sections`,
    method: "put",
    body: data,
    header: true,
  });
  return api.data;
};

export const getMappedSections = async (componentId) => {
  const api = await apiRequest({
    url: `/component/${componentId}/sections`,
    method: "get",
    header: true,
  });
  return api.data;
};

export const getSectionDetails = async (sectionId,componentId) => {
  const api = await apiRequest({
    url: `/component/${componentId}/sectiondetails/${sectionId}`,
    method: "get",
    header: true,
  });
  return api.data;
}

export const removeSectionComponent = async (componentId, sectionId) => {
  const api = await apiRequest({
    url: `/component/${componentId}/sections/${sectionId}`,
    method: "delete",
    header: true,
  });
  return api.data;
}