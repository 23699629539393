import React, { useState, useEffect } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { getPreviewProposal } from "../../services/proposalApiCall";
import htmlDocx from "html-docx-js/dist/html-docx";

const PreviewProposal = ({ cancel, ProposalID }) => {
  const [proposalData, setProposalData] = useState([]);

  useEffect(() => {
    fetchProposalData(ProposalID);
  }, [ProposalID]);

  const fetchProposalData = async (ProposalID) => {
    try {
      const response = await getPreviewProposal(ProposalID);
      if (response && response.data) {
        setProposalData(response.data);
      }
    } catch (error) {
      console.error("Error fetching proposal data:", error);
    }
  };

  const handleCancel = () => {
    cancel();
  };

  const handleDownload = async () => {
    // Combine section names and contents into a single string
    const combinedContent = proposalData
      .map(
        (item) =>
          `<h5>${item.SectionName}</h5><p>${item.SectionContent}</p>`
      )
      .join("");

    try {
      // Convert HTML to docx format
      const docxContent = htmlDocx.asBlob(combinedContent);

      // Create an anchor element to initiate download
      const element = document.createElement("a");

      // Set properties for the anchor element
      element.href = URL.createObjectURL(docxContent);
      element.download = "proposal.docx";

      // Append the anchor element to the document body (required for Firefox)
      document.body.appendChild(element);

      // Initiate the download
      element.click();

      // Clean up by revoking the object URL
      URL.revokeObjectURL(element.href);

      // Remove the anchor element from the document body
      document.body.removeChild(element);
    } catch (error) {
      console.error("Error converting HTML to docx:", error);
    }
  };

  return (
    <>
      <Row className="m-4">
        <Col xs={12}>
          <Card className="custom-card mt-1">
            <Card.Body className="p-4">
              <div className="container">
                <h4 className="mb-4">Preview & Download Proposal</h4>
              </div>

              <div
                className="container overflow-auto border p-4"
                style={{ height: "400px" }}
              >
                {proposalData &&
                  proposalData.map((item, index) => (
                    <div key={index}>
                      <h5>{item.SectionName}</h5>
                      <p
                        dangerouslySetInnerHTML={{ __html: item.SectionContent }}
                      ></p>
                    </div>
                  ))}
              </div>

              <div className="d-flex mt-5 justify-content-end">
                <Button variant="primary" className="me-3 mt-2" onClick={handleCancel}>
                  Back
                </Button>
                <Button variant="primary" className="me-3 mt-2" onClick={handleDownload}   >
                  Download
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PreviewProposal;
