import React, { useEffect, useState } from "react";
import ChooseSection from "./ChooseSection";
import { Card, Row, Col, Form, Button } from "react-bootstrap";
import Sidebar from "../../component/Sidebar";
import { getAllCustomers } from "../../services/customerApiCall";
import { getAllComponents } from "../../services/componentApiCall";
import {
  addProposal,
  updateProposal,
  getProposalComponent,
} from "../../services/proposalApiCall";
import PreviewProposal from "./PreviewProposal";

const NewProposalStep1 = ({
  handleCancelProposalstep1,
  handleHideAll,
  handleshowProposalStep1,
  isEdit,
  Proposal,
}) => {
  const [showSelectSection, setShowSelectSection] = useState(false);
  const [customerData, setCustomerData] = useState([]);
  const [componentData, setComponentData] = useState([]);
  const [errorMessages, setErrorMessages] = useState([]); // Add this line
  const [selectedComponent, setSelectedComponent] = useState([]); // Add this line
  const [ProposalID, setProposalID] = useState(""); // Add this line
  const [showPreviewProposal, setShowPreviewProposal] = useState(false); // Add this line
  const [formData, setFormData] = useState({
    CustomerID: "",
    ProjectTitle: "",
    ProjectDetails: "",
  });



  useEffect(() => {
    if (isEdit) {
      setFormData({
        CustomerID: Proposal.CustomerID,
        ProjectTitle: Proposal.Title,
        ProjectDetails: Proposal.Details,
      });
      fetchProposalComponent(Proposal.ProposalID);
      setProposalID(Proposal.ProposalID);
    }  
    

  }, [isEdit, Proposal]);

  useEffect(() => {
    fetchCustomers();
    fetchComponents();
  }, []);

  const fetchProposalComponent = async () => {
    try {
      const response = await getProposalComponent(Proposal.ProposalID);
      if (response && response.data) {
        const componentIDs = response.data.map((component) => component.ComponentID);
        setSelectedComponent(componentIDs);
      }
    } catch (error) {
      console.log(error);
    }
  };
  

  const fetchCustomers = async () => {
    try {
      const response = await getAllCustomers();
      if (response && response.data) {
        setCustomerData(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchComponents = async () => {
    try {
      const response = await getAllComponents();
      if (response && response.data) {
        setComponentData(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnClickedNext = async (e) => {
    e.preventDefault();
    try {
      // Validation
      const errors = {};
      if (!formData.ProjectTitle) {
        errors.ProjectTitle = "Project Title is required";
      }
      if (!formData.ProjectDetails) {
        errors.ProjectDetails = "Project Details is required";
      }
      if (!formData.CustomerID) {
        errors.CustomerID = "Organisation is required";
      }
      if (selectedComponent.length === 0) {
        errors.ProjectComponent = "Project Component is required";
      }
      setErrorMessages(errors);

      // If there are errors, stop the function
      if (Object.keys(errors).length > 0) {
        return;
      }
      console.log("formdata", formData);

      if (Object.keys(errors).length === 0) {

        const proposalData = {
          CustomerID: formData.CustomerID,
          ProjectTitle: formData.ProjectTitle,
          ProjectDetails: formData.ProjectDetails,
          ProjectComponent: selectedComponent,
        };

        if (isEdit) {
          const response = await updateProposal(proposalData, Proposal.ProposalID);
          if (response && response.success === true) {
            setShowSelectSection(true);
            handleHideAll(); 
          } else {
            console.log("Error", response);
          }
        } 
        else if (ProposalID) {
          const response = await updateProposal(proposalData, ProposalID);
          if (response && response.success === true) {
            setShowSelectSection(true);
            handleHideAll(); 
          } else {
            console.log("Error", response);
          }
        }
        else {
          const response = await addProposal(proposalData);

          if (response && response.success === true) {
            setShowSelectSection(true);
            handleHideAll(); // Call handleHideAll function here
            setProposalID(response.data.ProposalID); 
          } else {
            console.log("Error", response);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancelChooseSection = () => {
    setShowSelectSection(false);
    handleshowProposalStep1(); // Call handleshowProposalStep1 function here
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  


  const handleCheckboxChange = (e) => {
    const { id , checked } = e.target;
    if (isEdit) {
      const isComponentChecked = selectedComponent.includes(parseInt(id));
      if (isComponentChecked) {
      
        setSelectedComponent((prevSelectedComponent) =>
          prevSelectedComponent.filter((component) => component != id)
        );
      } else {
        setSelectedComponent((prevSelectedComponent) => [
          ...prevSelectedComponent,
          parseInt(id),
        ]);
      }
    } else {
      if (checked) {
      setSelectedComponent((prevSelectedComponent) => [
        ...prevSelectedComponent,
        parseInt(id),
      ]);
      } else {
       setSelectedComponent((prevSelectedComponent) =>
        prevSelectedComponent.filter((component) => component != id)
      );
      }
    }
  }

  const handleCancel = (e) => {
    e.preventDefault();
    handleCancelProposalstep1();
    setFormData({
      CustomerID: "",
      ProjectTitle: "",
      ProjectDetails: "",
    });
    setSelectedComponent([]);
     
  };

const handleClickOnPreview = () => {
  setShowPreviewProposal(true);
}

const handleCancelPreviewProposal = () => {
  setShowPreviewProposal(false);
}

  return (
    <>
      {!showSelectSection &&   
      !showPreviewProposal &&
      (
        <Sidebar>
          <Row>
            <Col xs={12}>
              <Card className="custom-card mt-1">
                <Card.Body className="p-4">
                  <div className="container">
                    <h4 className="mb-4">Add New Proposal</h4>
                    <Form>
                      <Row className="mb-3">
                        <Col xs={6}>
                          <Form.Group controlId="customer">
                            <Form.Label className="mb-2">
                              Organisation
                            </Form.Label>
                            <Form.Select
                              className="mb-3"
                              name="CustomerID"
                              value={formData.CustomerID}
                              onChange={handleChange}
                            >
                              <option>Select Organisation</option>
                              {customerData.map((customer, index) => (
                                <option key={index} value={customer.CustomerID}>
                                  {customer.CustomerName}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col xs={6}>
                          <Form.Group controlId="projectTitle">
                            <Form.Label className="mb-2">
                              Project Title
                            </Form.Label>
                            <Form.Control
                              type="text"
                              className="mb-3"
                              name="ProjectTitle"
                              value={formData.ProjectTitle}
                              onChange={handleChange}
                            />
                            {errorMessages.ProjectTitle && (
                              <Form.Text className="text-danger">
                                {errorMessages.ProjectTitle}
                              </Form.Text>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>
                      <Form.Group controlId="projectComponent" className="mb-3">
                        <Form.Label className="mb-2">
                          Project Component
                        </Form.Label>

                        <Row>
                          {componentData.map((component, index) => (
                            <Col xs={3} key={index}>
                              <Form.Check
                                type="checkbox"
                                label={component?.ComponentName}
                                className="mb-2"
                                style={{ whiteSpace: "nowrap" }} // Prevent line breaks in long component names
                                name="ProjectComponent"
                                id={component.ComponentID}
                                value={component.ComponentID}
                                onChange={handleCheckboxChange}
                                checked={selectedComponent?.includes(
                                  component.ComponentID
                                )}
                              />
                            </Col>
                          ))}
                        </Row>
                      </Form.Group>

                      <Form.Group controlId="projectDetails" className="mb-3">
                        <Form.Label className="mb-2">
                          Project Details
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          style={{ minHeight: "100px" }}
                          name="ProjectDetails"
                          value={formData.ProjectDetails}
                          onChange={handleChange}
                        />
                        {errorMessages.ProjectDetails && (
                          <Form.Text className="text-danger">
                            {errorMessages.ProjectDetails}
                          </Form.Text>
                        )}
                      </Form.Group>
                      <div className="d-flex justify-content-end">
                        <Button
                          variant="danger"
                          onClick={(e) => handleCancel(e)}
                          className="me-3"
                        >
                          Cancel
                        </Button>
                        {ProposalID ? (
                          <>
                          <Button
                            variant="primary"
                            onClick={(e) => handleOnClickedNext(e)}
                          >
                            Update
                          </Button>
                          <Button
                            variant="primary ms-3"
                            onClick={()=>{
                              setShowSelectSection(true);
                              handleHideAll();
                            }}
                          >
                            Go to Sections
                          </Button>
                          <Button 
                            variant="primary ms-3"
                            onClick={handleClickOnPreview}
                          >
                            Preview
                          </Button>
                          </>

                        ) : (
                          <Button
                          variant="primary"
                          onClick={(e) => handleOnClickedNext(e)}
                        >
                          Save & Next
                        </Button>
                        )}
                      </div>
                    </Form>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Sidebar>
      )}
      {showSelectSection && (
        <ChooseSection
          handleCancelChooseSection={handleCancelChooseSection}
          handleHideAll={handleHideAll}
          isEdit={isEdit}
          ProposalID={ProposalID}
          selectedComponent={selectedComponent}
        />
      )}

      {
        showPreviewProposal && (
          <PreviewProposal
            cancel={handleCancelPreviewProposal}
            ProposalID={ProposalID}
          />
        )
      }
    </>
  );
};

export default NewProposalStep1;
