import React, { useEffect, useState } from "react";
import Topbar from "../../component/Topbar";
import {
  Card,
  Col,
  Row,
  Button,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import AddCustomer from "./AddCustomer";
import Sidebar from "../../component/Sidebar";
import {
  getAllCustomers,
  deleteCustomer,
} from "../../services/customerApiCall";
import Swal from "sweetalert2";

const Customer = () => {
  const [showAddCustomer, setShowAddCustomer] = useState(false);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [customerId, setCustomerId] = useState(null);
  const handleAddCustomerClose = () => {
    setShowAddCustomer(false);
    fetchCustomers();
    setIsUpdate(false);
  };
  const [customers, setCustomers] = useState([]);
  const [search, setSearch] = useState("");

  const fetchCustomers = async () => {
    try {
      const response = await getAllCustomers();
      if (response && response.data) {
        setCustomers(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCustomers();
    setIsUpdate(false);
  }, []);

  useEffect(() => {
    if (search) {
      const filteredData = customers.filter(
        (customer) =>
          customer.CustomerName.toLowerCase().includes(search.toLowerCase()) ||
          customer.Email.toLowerCase().includes(search.toLowerCase()) ||
          customer.Phone.toLowerCase().includes(search.toLowerCase())
      );
      setFilteredCustomers(filteredData);
    } else {
      setFilteredCustomers(customers);
    }
  }, [search, customers]);

  const handleDeleteCustomer = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You want to delete this customer!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, keep it",
      });

      if (result.isConfirmed) {
        await deleteCustomer(id);
        fetchCustomers();
      }
    } catch (error) {
      console.log(error);
      // Handle error here
      Swal.fire({
        title: "Error",
        text: "An error occurred while deleting the customer.",
        icon: "error",
      });
    }
  };

  const handleUpdateCustomer = async (id) => {
    setIsUpdate(true);
    setCustomerId(id);
    setShowAddCustomer(true);
  };

  return (
    <>
      <Topbar />
      <Sidebar>
        {!showAddCustomer && (
          <>
            <div className="page-heading">Customers</div>
            <div className="p-2">
              <Row className="mb-3">
                <Col xs={4}>
                  <InputGroup>
                    <FormControl
                      placeholder="Search"
                      aria-label="Search"
                      aria-describedby="basic-addon2"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <Button variant="primary" id="button-addon2 ">
                      <i className="fas fa-search"></i>
                    </Button>
                  </InputGroup>
                </Col>
                <Col xs={8} className="text-end">
                  <Button
                    variant="primary"
                    onClick={() => setShowAddCustomer(true)}
                  >
                    <i className="fas fa-plus me-2"></i> New Customer
                  </Button>
                </Col>
              </Row>
              <hr />

              {filteredCustomers?.length > 0 ? (
                filteredCustomers?.map((customer) => (
                  <Row className="mt-3 customer-css mx-1">
                    <Col xs={3}>
                      <div className="customer-name">
                        {customer.CustomerName}
                      </div>
                    </Col>
                    <Col xs={3}>
                      <div className="customer-name">{customer.Email}</div>
                    </Col>
                    <Col xs={3}>
                      <div className="customer-name">{customer.Phone}</div>
                    </Col>
                    <Col xs={3}>
                      <div className="action-icons">
                        <Link
                          className="action-icon mr-2"
                          onClick={() =>
                            handleUpdateCustomer(customer.CustomerID)
                          }
                        >
                          <i className="fas fa-edit"></i>
                        </Link>
                        <Link
                          className="action-icon"
                          onClick={() =>
                            handleDeleteCustomer(customer.CustomerID)
                          }
                        >
                          <i className="fas fa-trash-alt"></i>
                        </Link>
                      </div>
                    </Col>
                  </Row>
                ))
              ) : (
                <div className="text-center">
                  <h4>No Customers Found</h4>
                </div>
              )}
            </div>
          </>
        )}

        {showAddCustomer && (
          <AddCustomer
            close={handleAddCustomerClose}
            isUpdate={isUpdate}
            customerId={customerId}
          />
        )}
      </Sidebar>
    </>
  );
};

export default Customer;
