import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Avatar from "../assets/avatar.png";

const Topbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  useEffect(() => {
    const closeDropdown = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", closeDropdown);

    return () => document.removeEventListener("click", closeDropdown);
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    localStorage.clear();
     window.location.href = "/";
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
    <div className="container-fluid px-0">
      <div className="px-4">
        <a href="https://acompworld.com/" className="navbar-brand" target="_blank">
          <img src="/static/logo.png" alt="logo" style={{ height: "50px" }} />
        </a>
      </div>
      <button
        className="navbar-toggler "
        type="button"
        onClick={toggleDropdown}
      >
        <span className="navbar-toggler-icon "></span>
      </button>
  
      <div className={"collapse navbar-collapse  " + (isOpen ? "show" : "")}>
        <ul className="navbar-nav ms-auto " ref={dropdownRef}>
          <li className="nav-item dropdown">
            <button
              className="nav-link dropdown-toggle btn btn-link"
              id="navbarDropdown"
              role="button"
              aria-expanded="false"
              onClick={toggleDropdown}
            >
              <img
                src={Avatar}
                alt="User Avatar"
                style={{ height: "50px", borderRadius: "50%" }}
              />
            </button>
            <ul
              className={"dropdown-menu dropdown-menu-end " + (isOpen ? "show" : "")}
              aria-labelledby="navbarDropdown"
            >
              <li>
                <button className="dropdown-item" onClick={handleLogout}>
                  Logout
                </button>
              </li>
              {/* Add more dropdown items here */}
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  
  );
};

export default React.memo(Topbar);
