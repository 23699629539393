import React, { useState, useEffect } from "react";
import { Card, Col, Row, Button, Form } from "react-bootstrap";
import Sidebar from "../../component/Sidebar";
import MapSection from "./MapSection";
import { addComponent, updateComponent } from "../../services/componentApiCall";

const AddComponent = ({ close, isUpdate, component }) => {
  const [showMapSection, setShowMapSection] = useState(false);
  const [ComponentName, setComponentName] = useState("");
  const [errorMessages, setErrorMessages] = useState({});
  const [componentId, setComponentId] = useState(null);

  useEffect(() => {
    if (isUpdate) {
      setComponentName(component.ComponentName);
      setComponentId(component.ComponentID);
    }
  }, [isUpdate, component]);

  const handleOnClickedNext = async (update) => {
    try {
      if (!ComponentName) {
        setErrorMessages({ ComponentName: "Component Name is required" });
        return;
      }

      const data = {
        ComponentName,
      };

      if (isUpdate) {
        await updateComponent(data, componentId);
        if (update === "update") {
          close();
          return;
        }
      } else {
        const response =  await addComponent(data);
        if (response && response.data) {
          setComponentId(response.data.ComponentID);
        }
      }

      setErrorMessages({});
      setShowMapSection(true);
    } catch (error) {}
  };

  const handleCancelMapSection = () => {
    setShowMapSection(false);
    close();
  };

  return (
    <>
      {!showMapSection && (
        <Sidebar>
          <Row>
            <Col xs={12}>
              <Card className="custom-card mt-1">
                <Card.Body className="p-4">
                  <div className="container">
                    <h4 className="mb-4">
                      {isUpdate ? "Edit Component" : "Add Component"}
                    </h4>
                    <Form>
                      <Form.Group className="mb-3">
                        <Form.Label>Component Name</Form.Label>
                        <Form.Control
                          type="text"
                          id="componentName"
                          value={ComponentName}
                          onChange={(e) => setComponentName(e.target.value)}
                        />
                        {errorMessages?.ComponentName && (
                          <small className="error ">
                            {errorMessages.ComponentName}
                          </small>
                        )}
                      </Form.Group>

                      <div className="d-flex mt-5 justify-content-end">
                        {/* <Button
                          variant="primary"
                          className="me-3 mt-2"
                          onClick={close}
                        >
                          Cancel
                        </Button> */}
                        {componentId ? (
                          <>
                            <Button
                              variant="primary"
                              className="me-3 mt-2"
                              onClick={() => handleOnClickedNext("update")}
                            >
                              Update
                            </Button>

                            <Button
                              variant="primary"
                              className="me-3 mt-2"
                              onClick={handleOnClickedNext}
                            >
                              Go to Sections
                            </Button>
                          </>
                        ) : (
                          <Button
                            variant="primary"
                            className="me-3 mt-2"
                            onClick={handleOnClickedNext}
                          >
                            Save & Next
                          </Button>
                        )}
                      </div>
                    </Form>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Sidebar>
      )}

      {showMapSection && (
        <MapSection
          close={handleCancelMapSection}
          ComponentName={ComponentName}
          isUpdate={isUpdate}
          ComponentID={componentId}
          handleBack={() => setShowMapSection(false)}
        />
      )}
    </>
  );
};

export default AddComponent;
