import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Form, Row, Col, Button, Card } from "react-bootstrap";
import {
  getSectionById,
  updateSection,
  addSection,
} from "../../services/sectionApiCall";
import Swal from "sweetalert2";

const AddNewSection = ({ close, isUpdate, sectionId }) => {
  const [sectionDescription, setSectionDescription] = useState("");
  const [sectionName, setSectionName] = useState("");
  const [errorMessages, setErrorMessages] = useState({});
  

  useEffect(() => {
    if (isUpdate) {
      getSectionData(sectionId);
    }
  }, [isUpdate]);

  const getSectionData = async (sectionId) => {
    try {
      const response = await getSectionById(sectionId);
      if (response && response.data) {
        setSectionName(response.data.SectionName);
        setSectionDescription(response.data.SectionContent);
      }
    } catch (error) {
      console.log(error);
    }
  };



  const handleAddUpdateSection = async () => {
    let errors = {};
    if (!sectionName) {
      errors.sectionName = "Section Name is required";
    }
    if (!sectionDescription) {
      errors.sectionDescription = "Section Description is required";
    }
    if (Object.keys(errors).length > 0) {
      setErrorMessages(errors);
      return;
    }

    if (Object.keys(errors).length === 0) {
      const sectionData = {
        SectionName: sectionName,
        SectionContent: sectionDescription,
      };

      

      if (isUpdate) {
        try {
          const response = await updateSection(sectionId, sectionData);
          if (response && response.success == true) {
            close();
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Section Updated Successfully",
            });
          }
        } catch (error) {
          console.log(error);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: error.response.data.message,
            });
        }
      } else {
        try {
          const response = await addSection(sectionData);
          if (response && response.success == true) {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Section Added Successfully",
            });
            close();
          }
        } catch (error) {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.response.data.message,
          });
        }
      }
    }
  };

  return (
    <>
      <Row>
        <Col xs={12}>
          <Card className="custom-card mt-1">
            <Card.Body className="p-4">
              <div className="container">
                <h4 className="mb-4">
                  {isUpdate ? "Edit Section" : "Add New Section"}
                </h4>
                <Form className="mt-3">
                  <Form.Group className="mb-3">
                    <Form.Label>Section Title</Form.Label>
                    <Form.Control
                      type="text"
                      id="sectionTitle"
                      value={sectionName}
                      onChange={(e) => setSectionName(e.target.value)}
                      onFocus={(e) => setErrorMessages({})}
                    />
                    {errorMessages?.sectionName && (
                      <small className="error">
                        {errorMessages.sectionName}
                      </small>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-5">
                    <Form.Label>Section Description</Form.Label>
                    <ReactQuill
                      theme="snow"
                      value={sectionDescription}
                      onChange={setSectionDescription}
                      style={{ height: "200px" }}
                      onFocus={(e) => setErrorMessages({})}
                    />
                  </Form.Group>
                  {errorMessages?.sectionDescription && (
                      <small className="error ">
                        {errorMessages.sectionDescription}
                      </small>
                    )}

                  <div className="d-flex justify-content-end ">
                    <Button
                      variant="primary"
                      className="me-3 mt-2"
                      onClick={handleAddUpdateSection}
                    >
                      {isUpdate ? "Update" : "Save"}
                    </Button>
                    <Button
                      variant="primary"
                      className="me-3 mt-2"
                      onClick={close}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AddNewSection;
