import React, { useState, useEffect } from "react";
import {
  addCustomer,
  updateCustomer,
  getCustomer,
} from "../../services/customerApiCall";
import Swal from "sweetalert2";
import { Form, Row, Col, Button, Card } from "react-bootstrap";

const AddCustomer = ({ close, isUpdate, customerId }) => {
  const [formData, setFormData] = useState({
    customerName: "",
    contactPerson: "",
    emailAddress: "",
    address: "",
    contactNumber: "",
  });

  useEffect(() => {
    if (isUpdate) {
      fetchCustomer(customerId);
    }
  }, [isUpdate, customerId]);

  const [error, setError] = useState({});
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errors = {};
    if (!formData.customerName) {
      errors.customerName = "Customer Name is required";
    }
    if (!formData.contactPerson) {
      errors.contactPerson = "Contact Person is required";
    }
    if (!formData.emailAddress) {
      errors.emailAddress = "Email Address is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.emailAddress)) {
      errors.emailAddress = "Email Address is invalid";
    }
    if (!formData.address) {
      errors.address = "Address is required";
    }
    if (!formData.contactNumber) {
      errors.contactNumber = "Contact Number is required";
    }
    if (Object.keys(errors).length > 0) {
      setError(errors);
      return;
    }

    if (Object.keys(errors).length === 0) {
      const customerData = {
        CustomerName: formData.customerName,
        ContactPerson: formData.contactPerson,
        Email: formData.emailAddress,
        Address: formData.address,
        Phone: formData.contactNumber,
      };

      try {
        let response;
        if (isUpdate) {
          response = await updateCustomer(customerData, customerId);
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Customer Updated Successfully",
          });
        } else {
          response = await addCustomer(customerData);
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Customer Added Successfully",
          });
        }
        if (response.success === true) {
          close();
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      }
    }
  };

  const handleOnFocus = (e) => {
    setError({
      ...error,
      [e.target.name]: "",
    });
  };

  const fetchCustomer = async (customerId) => {
    try {
      const response = await getCustomer(customerId);
      if (response && response.data) {
        setFormData({
          customerName: response.data[0]?.CustomerName,
          contactPerson: response.data[0]?.ContactPerson,
          emailAddress: response.data[0]?.Email,
          address: response.data[0]?.Address,
          contactNumber: response.data[0]?.Phone,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancel = () => {
    setFormData({
      customerName: "",
      contactPerson: "",
      emailAddress: "",
      address: "",
      contactNumber: "",
    });
    close();
  };

  return (
    <>
      <Row>
        <Col xs={12}>
          <Card className="custom-card mt-1">
            <Card.Body className="p-4">
              <div className="container">
                <h4 className="mb-4">
                  {isUpdate ? "Edit Customer" : "Add Customer"}
                </h4>
                <Form className="mt-3">
                  <Row className="mb-3">
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Customer Name</Form.Label>
                        <Form.Control
                          type="text"
                          id="customerName"
                          onChange={handleInputChange}
                          name="customerName"
                          value={formData.customerName}
                          onFocus={handleOnFocus}
                        />
                        {error.customerName && (
                          <small className="error">{error.customerName}</small>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Contact Person</Form.Label>
                        <Form.Control
                          type="text"
                          id="contactPerson"
                          onChange={handleInputChange}
                          name="contactPerson"
                          value={formData.contactPerson}
                          onFocus={handleOnFocus}
                        />
                        {error.contactPerson && (
                          <small className="error">{error.contactPerson}</small>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label className="mb-2">Email Address</Form.Label>
                        <Form.Control
                          type="email"
                          id="emailAddress"
                          onChange={handleInputChange}
                          name="emailAddress"
                          value={formData.emailAddress}
                          onFocus={handleOnFocus}
                        />
                        {error.emailAddress && (
                          <small className="error">{error.emailAddress}</small>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label className="mb-2">Contact Number</Form.Label>
                        <Form.Control
                          type="text"
                          id="contactNumber"
                          onChange={handleInputChange}
                          name="contactNumber"
                          value={formData.contactNumber}
                          onFocus={handleOnFocus}
                        />
                        {error.contactNumber && (
                          <small className="error">{error.contactNumber}</small>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>

                  <Form.Group className="mb-3">
                    <Form.Label className="mb-2">Address</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      id="address"
                      onChange={handleInputChange}
                      name="address"
                      value={formData.address}
                      onFocus={handleOnFocus}
                      style={{ minHeight: "100px" }}
                    />
                    {error.address && (
                      <small className="error">{error.address}</small>
                    )}
                  </Form.Group>

                  <div className="d-flex justify-content-end">
                    <Button
                      variant="secondary"
                      className="me-2"
                      onClick={() => handleCancel()}
                    >
                      Cancel
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                      {isUpdate ? "Update" : "Submit"}
                    </Button>
                  </div>
                </Form>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AddCustomer;
