import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
import ReactQuill from "react-quill";
import {
  getAllSectionSubSection,
  mapSectionComponent,
  getMappedSections,
  updateSectionComponent,
  getSectionDetails,
  removeSectionComponent,
} from "../../services/componentApiCall";
import Swal from "sweetalert2";
import Sidebar from "../../component/Sidebar";

const MapSection = ({
  close,
  ComponentName,
  isUpdate,
  ComponentID,
  handleBack,
}) => {
  const [sectionData, setSectionData] = useState([]);
  const [checkedSubsectionIds, setCheckedSubsectionIds] = useState([]);
  const [description, setDescription] = useState("");
  const [selectedSectionName, setSelectedSectionName] = useState("");
  const [SectionID, setSectionID] = useState(0);

  useEffect(() => {
    fetchSections();
  }, []);

  useEffect(() => {
    if (isUpdate) {
      fetchMapSections(ComponentID);
    } else {
      setDescription("");
    }
  }, [isUpdate, ComponentID]);

  const fetchMapSections = async (id) => {
    try {
      const response = await getMappedSections(id);
      if (response && response.data) {
        // setDescription(response.data[0].DefaultContent);

        console.log("maped section", response.data);
        const mappedSections = response.data.map(
          (section) => section.SectionID
        );
        setCheckedSubsectionIds(mappedSections);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSections = async () => {
    try {
      const response = await getAllSectionSubSection();
      if (response && response.data) {
        setSectionData(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddUpdateComponent = async () => {
    try {
      const Sectiondata = {
        SectionID: SectionID,
        DefaultContent: description,
      };

      // const response = isUpdate
      //   ? await updateSectionComponent(Sectiondata, ComponentID)
      //   : await mapSectionComponent(Sectiondata, ComponentID);
      const response = await mapSectionComponent(Sectiondata, ComponentID);
      if (response && response.success === true) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Section updated successfully",
        });
        setSectionID(0);
        setSelectedSectionName("");
        setDescription("");
        fetchMapSections(ComponentID);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCheckboxChange = async (event, section) => {
    const { id, checked } = event.target;
    await handleOnClickSection(section);
    if (isUpdate) {
      const isSectionChecked = checkedSubsectionIds.includes(parseInt(id));

      if (!checked) {
        // If it's an update and section is unchecked, remove the section component
        const res = await removeSectionComponent(ComponentID, parseInt(id));
        if (res && res.success === true) {
          await fetchMapSections(ComponentID);
          setSectionID(0);
          setSelectedSectionName("");
          setDescription("");
        }

        return;
      } else {
        const Sectiondata = {
          SectionID: parseInt(id),
          DefaultContent: description,
        };
        const response = await mapSectionComponent(Sectiondata, ComponentID);
        if (response && response.success === true) {
          await fetchMapSections(ComponentID);
        }
      }

      if (checked) {
        setCheckedSubsectionIds((prevCheckedSubsectionIds) => [
          ...prevCheckedSubsectionIds,
          parseInt(id),
        ]);
      } else {
        setCheckedSubsectionIds((prevCheckedSubsectionIds) =>
          prevCheckedSubsectionIds.filter((subsectionId) => subsectionId !== id)
        );
      }
    } else {
      if (checked) {
        setCheckedSubsectionIds((prevCheckedSubsectionIds) => [
          ...prevCheckedSubsectionIds,
          parseInt(id),
        ]);
      } else {
        setCheckedSubsectionIds((prevCheckedSubsectionIds) =>
          prevCheckedSubsectionIds.filter((subsectionId) => subsectionId !== id)
        );
      }
    }
  };

  const handleOnClickSection = async (section) => {
    setSectionID(section.SectionID);
    setSelectedSectionName(section.SectionName);
    const response = await getSectionDetails(section.SectionID, ComponentID);
    console.log("response", response);
    if (response && response.data) {
      setDescription(response?.data?.DefaultContent);
    } else {
      setDescription("");
    }
  };

  return (
    <>
      <Sidebar>
        <div className="page-heading">Component: {ComponentName}</div>
        <Container fluid className="mt-3">
          <Row>
            <Col xs={3}>
              <div className="sidebar card ms-1">
                <div className="sidebar-content card-body">
                  <h3>Choose Section</h3>
                  <hr />
                  <Form>
                    {sectionData.map((section) => (
                      <div key={section.SectionID} className="mb-3 ms-3">
                        {/* Checkbox for main section */}
                        <Form.Check
                          type="checkbox"
                          id={section.SectionID}
                          onChange={(e) => handleCheckboxChange(e, section)}
                          checked={checkedSubsectionIds.includes(
                            section.SectionID
                          )}
                        />
                        <Form.Label
                          onClick={() => {
                            handleOnClickSection(section);
                          }}
                          className={`${
                            selectedSectionName === section.SectionName
                              ? "active"
                              : ""
                          } secton-label`}
                          style={{ cursor: "pointer" }}
                        >
                          {section.SectionName}
                        </Form.Label>

                        {/* Subsections */}
                        {section.subsections &&
                          section.subsections.length > 0 && (
                            <ul className="list-unstyled">
                              {section.subsections.map((subsection) => (
                                <li key={subsection.SectionID}>
                                  <Form.Check
                                    type="checkbox"
                                    id={subsection.SectionID}
                                    onChange={(e) =>
                                      handleCheckboxChange(e, subsection)
                                    }
                                    className="ms-4"
                                    checked={checkedSubsectionIds.includes(
                                      subsection.SectionID
                                    )}
                                  />
                                  <Form.Label
                                    className={`${
                                      selectedSectionName ===
                                      subsection.SectionName
                                        ? "active"
                                        : ""
                                    } secton-label ms-4`}
                                    onClick={() => {
                                      handleOnClickSection(subsection);
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {subsection.SectionName}
                                  </Form.Label>
                                </li>
                              ))}
                            </ul>
                          )}
                      </div>
                    ))}
                  </Form>
                </div>
              </div>
            </Col>

            <Col xs={9}>
              <Card className="custom-card mt-1">
                <Card.Body className="p-4">
                  <div className="container">
                    <Form className="mb-3 mt-4">
                      <Form.Group controlId="projectDetails" className="mb-3">
                        <Form.Label className="mb-2">
                          {/* <h5 className="mb-4"> Company Introduction</h5> */}
                          <h5 className="mb-4">
                            {selectedSectionName
                              ? selectedSectionName
                              : "Select Section"}
                          </h5>
                        </Form.Label>
                        <ReactQuill
                          theme="snow"
                          value={description}
                          onChange={setDescription}
                          style={{
                            height: "325px",
                            borderRadius: "5px",
                            marginBottom: "20px",
                          }}
                        />
                      </Form.Group>
                    </Form>
                    <div className="d-flex mt-5 justify-content-end">
                      <Button
                        variant="primary"
                        className="me-3 mt-2"
                        onClick={handleBack}
                      >
                        Back
                      </Button>

                      <Button
                        variant="primary"
                        className="me-3 mt-2"
                        onClick={handleAddUpdateComponent}
                      >
                        Update
                      </Button>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Sidebar>
    </>
  );
};

export default MapSection;
