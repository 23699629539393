import React, { useEffect, useState } from "react";
import Topbar from "../../component/Topbar";
import { Col, Row, Button, InputGroup, FormControl } from "react-bootstrap";
import Sidebar from "../../component/Sidebar";
import AddComponent from "./AddComponent";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import {
  getAllComponents,
  deleteComponent,
} from "../../services/componentApiCall";

const ComponentSetting = () => {
  const [showAddNewComponent, setShowAddNewComponent] = useState(false);
  const [search, setSearch] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [componentData, setComponentData] = useState([]);
  const [filteredComponents, setFilteredComponents] = useState([]);

  useEffect(() => {
    fetchComponents();
  }, []);

  const fetchComponents = async () => {
    try {
      const response = await getAllComponents();
      if (response && response.data) {
        setComponentData(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateComponent = (component) => {
    setIsUpdate(true);
    setSelectedData(component);
    setShowAddNewComponent(true);
  };

  const handleDeleteComponent = (componentId) => {
    try {
      Swal.fire({
        title: "Are you sure?",
        text: "You want to delete this Component?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          const response = deleteComponent(componentId);
          if (response) {
            Swal.fire("Deleted!", "Component has been deleted.", "success");
            fetchComponents();
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseAddComponent = () => {
    setShowAddNewComponent(false);
    setIsUpdate(false);
    setSelectedData(null);
    fetchComponents();
  };

  useEffect(() => {
    if (search) {
      const filteredData = componentData.filter((component) =>
        component.ComponentName.toLowerCase().includes(search.toLowerCase())
      );
      setFilteredComponents(filteredData);
    } else {
      setFilteredComponents(componentData);
    }
  }, [search, componentData]);

  return (
    <>
      <Topbar />
      {!showAddNewComponent && (
        <Sidebar>
          <>
            <div className="page-heading">Components</div>

            <div className="p-4">
              <Row className="mb-3">
                <Col xs={4}>
                  <InputGroup>
                    <FormControl
                      placeholder="Search"
                      aria-label="Search"
                      aria-describedby="basic-addon2"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <Button variant="primary" id="button-addon2 ">
                      <i className="fas fa-search"></i>
                    </Button>
                  </InputGroup>
                </Col>
                <Col xs={8} className="text-end">
                  <Button
                    variant="primary"
                    onClick={() => setShowAddNewComponent(true)}
                  >
                    <i className="fas fa-plus me-2"></i> Add New Component
                  </Button>
                </Col>
              </Row>
              <hr />

              {filteredComponents &&
                filteredComponents?.length > 0 ? (
                filteredComponents.map((component) => (
                  <Row className="mt-3 customer-css mx-1 ">
                    <Col xs={6}>
                      <div className="customer-name">
                        {component.ComponentName}
                      </div>
                    </Col>
                    <Col xs={3}>
                      <div className="customer-name">
                        Sections({component.NoOfSectionsMapped}) 
                      </div>
                    </Col>


                    <Col xs={3} className="ms-0">
                      <div className="action-icons ">
                        <Link
                          className="action-icon mr-2"
                          onClick={() =>
                            handleUpdateComponent(component)
                          }
                        >
                          <i className="fas fa-edit"></i>
                        </Link>
                        <Link
                          className="action-icon"
                          onClick={() =>
                            handleDeleteComponent(component.ComponentID)
                          }
                        >
                          <i className="fas fa-trash-alt"></i>
                        </Link>
                      </div>
                    </Col>
                  </Row>
                ))
              ) : (
                <div className="text-center mt-5">
                  No Components Found
                </div>
              )
              }
            </div>
          </>
        </Sidebar>
      )}

      {showAddNewComponent && (
        <>
          <AddComponent
            close={handleCloseAddComponent}
            isUpdate={isUpdate}
            component={selectedData}
          />
        </>
      )}
    </>
  );
};

export default ComponentSetting;
